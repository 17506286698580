import { Subject } from 'rxjs';
import { Card } from '@/core/models';

class Bus {
    stockClickEvent$ = new Subject();
}

export type CardClickEvent = {
    cardId: string;
    card: Card;
};

export const bus = new Bus();
