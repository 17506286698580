import { cardsService } from '@/state/cards/cards.service';
import { cardsQuery } from '@/state/cards/cards.query';
import { CardOwner } from '@/core/models';
import { coreBus } from '@/core/core-bus';
import { CardDealerBaseController } from '@/core/card-dealer.base.controller';

export class DealerController extends CardDealerBaseController {
    constructor() {
        super(30, 29);
    }

    protected dealCard(i: number) {
        const card = cardsQuery.getTopByOwner(CardOwner.stock);
        if (!card) {
            throw new Error('no card in stock');
        }

        if (i <= 28) {
            cardsService.update(card.id, {
                owner: CardOwner.peaks,
                isFaceUp: i >= 19,
                clickEnabled: i >= 19,
                order: i,
            });
        } else {
            cardsService.update(card.id, {
                owner: CardOwner.discard,
                isFaceUp: true,
                clickEnabled: false,
                order: 1,
            });
        }

        coreBus.cardMoveCmd$.next({
            duration: 0,
            cardId: card.id,
        });
    }
}
