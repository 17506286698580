<template>
    <div>
        <div class="frame" :style="discardStyle"></div>
        <div @click="stockClick" class="stock-overlay" :style="stockOverlayStyle"></div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { bus } from '@/games/tripeaks/bus';
import { display } from '@/games/tripeaks/display';
import { subscribeTo } from '@/core/rxjs-helpers';
import { gameQuery } from '@/state/game/game.query';
import { recomputable } from '@/composable/recomputable';

export default defineComponent({
    setup() {
        const recompute = ref(0);

        subscribeTo(gameQuery.gameSize$, () => {
            setTimeout(() => {
                recompute.value += 1;
            });
        });

        const getFrameStyle = (x: number, y: number) => {
            return {
                top: `${y}px`,
                left: `${x}px`,
                width: `${display.cardSize.width}px`,
                height: `${display.cardSize.height}px`,
                borderRadius: `${display.cardSize.width * 0.06}px`,
            };
        };

        const discardStyle = recomputable(recompute, () => {
            return getFrameStyle(display.discardPosition.x, display.discardPosition.y);
        });

        const stockOverlayStyle = recomputable(recompute, () => {
            return {
                top: `${display.stockPosition.y}px`,
                left: `${display.stockPosition.x}px`,
                width: `${display.cardSize.width}px`,
                height: `${display.cardSize.height}px`,
            };
        });

        const stockClick = () => {
            bus.stockClickEvent$.next();
        };

        return {
            discardStyle,
            stockOverlayStyle,
            stockClick,
        };
    },
});
</script>

<style scoped>
.frame {
    border: #777 1px solid;
    position: absolute;
    background-color: rgba(50, 50, 50, 0.5);
    pointer-events: none;
}

.stock-overlay {
    position: absolute;
    z-index: 99;
    cursor: pointer;
    pointer-events: all;
}
</style>
