import { Card, CardOwner } from '@/core/models';
import { cardsQuery } from '@/state/cards/cards.query';

class TriPeaksUtil {
    getCoverOrders(order: number): { left: number; right: number } | null {
        if (order == 1) {
            return { left: 4, right: 5 };
        } else if (order == 2) {
            return { left: 6, right: 7 };
        } else if (order == 3) {
            return { left: 8, right: 9 };
        } else if (order == 4) {
            return { left: 10, right: 11 };
        } else if (order == 5) {
            return { left: 11, right: 12 };
        } else if (order == 6) {
            return { left: 13, right: 14 };
        } else if (order == 7) {
            return { left: 14, right: 15 };
        } else if (order == 8) {
            return { left: 16, right: 17 };
        } else if (order == 9) {
            return { left: 17, right: 18 };
        } else if (order == 10) {
            return { left: 19, right: 20 };
        } else if (order == 11) {
            return { left: 20, right: 21 };
        } else if (order == 12) {
            return { left: 21, right: 22 };
        } else if (order == 13) {
            return { left: 22, right: 23 };
        } else if (order == 14) {
            return { left: 23, right: 24 };
        } else if (order == 15) {
            return { left: 24, right: 25 };
        } else if (order == 16) {
            return { left: 25, right: 26 };
        } else if (order == 17) {
            return { left: 26, right: 27 };
        } else if (order == 18) {
            return { left: 27, right: 28 };
        } else {
            return null;
        }
    }

    isPeaksCardExposed(card: Card) {
        const coverOrders = this.getCoverOrders(card.order);
        // if no cover orders then this is last row in pyramid then it exposed
        if (!coverOrders) {
            return true;
        }
        // if cover orders not exists in pyramid then the card not exposed
        const cards = cardsQuery.getAll().filter((c) => c.owner == CardOwner.peaks);
        const left = cards.filter((c) => c.order == coverOrders.left).length > 0;
        const right = cards.filter((c) => c.order == coverOrders.right).length > 0;
        return !(left || right);
    }
}

export const triPeaksUtil = new TriPeaksUtil();
