import { Position } from '@/core/models';
import { Card, CardOwner } from '@/core/models';
import { CardDisplayBase } from '@/core/card-display.base';

export class Display extends CardDisplayBase {
    stockPosition: Position = { x: 0, y: 0 };
    midPeakPosition: Position = { x: 0, y: 0 };
    leftPeakPosition: Position = { x: 0, y: 0 };
    rightPeakPosition: Position = { x: 0, y: 0 };
    discardPosition: Position = { x: 0, y: 0 };

    constructor() {
        super(10);
    }

    calcDisplay() {
        const cardWidth = this.cardSize.width;
        const cardHeight = this.cardSize.height;
        const spaceSize = this.spaceHorizontal;

        this.midPeakPosition = {
            x: this.gameSize.width / 2 - cardWidth / 2,
            y: spaceSize,
        };

        this.leftPeakPosition = {
            x: this.midPeakPosition.x - 3 * spaceSize - 3 * cardWidth,
            y: this.midPeakPosition.y,
        };

        this.rightPeakPosition = {
            x: this.midPeakPosition.x + 3 * spaceSize + 3 * cardWidth,
            y: this.midPeakPosition.y,
        };

        this.stockPosition = {
            x: this.gameSize.width / 2 - cardWidth * 2,
            y: cardHeight * 3.5,
        };

        this.discardPosition = {
            x: this.gameSize.width / 2 - cardWidth / 2,
            y: cardHeight * 3.5,
        };
    }

    calcCardPosition(card: Card): Position {
        if (card.owner == CardOwner.stock || card.owner == CardOwner.none) {
            const div = this.gameSize.width > 600 ? 4 : 6;
            return {
                x: this.stockPosition.x - (card.order - 1) / div,
                y: this.stockPosition.y - (card.order - 1) / div,
            };
        }

        if (card.owner == CardOwner.peaks) {
            return this.getPeakPosition(card);
        }

        if (card.owner == CardOwner.discard) {
            return { ...this.discardPosition };
        }

        return {
            x: 0,
            y: 0,
        };
    }

    private getPeakPosition(card: Card): Position {
        const row2y = this.midPeakPosition.y + this.cardSize.height * 0.5;
        const row3y = row2y + this.cardSize.height * 0.6;
        const row4y = row3y + this.cardSize.height * 0.6;

        if (card.order == 1) {
            return { ...this.leftPeakPosition };
        } else if (card.order == 2) {
            return { ...this.midPeakPosition };
        } else if (card.order == 3) {
            return { ...this.rightPeakPosition };
        } else if (card.order == 4) {
            return {
                x: this.leftPeakPosition.x - this.spaceHorizontal / 2 - this.cardSize.width / 2,
                y: row2y,
            };
        } else if (card.order == 5) {
            return {
                x: this.leftPeakPosition.x + this.spaceHorizontal / 2 + this.cardSize.width / 2,
                y: row2y,
            };
        } else if (card.order == 6) {
            return {
                x: this.midPeakPosition.x - this.spaceHorizontal / 2 - this.cardSize.width / 2,
                y: row2y,
            };
        } else if (card.order == 7) {
            return {
                x: this.midPeakPosition.x + this.spaceHorizontal / 2 + this.cardSize.width / 2,
                y: row2y,
            };
        } else if (card.order == 8) {
            return {
                x: this.rightPeakPosition.x - this.spaceHorizontal / 2 - this.cardSize.width / 2,
                y: row2y,
            };
        } else if (card.order == 9) {
            return {
                x: this.rightPeakPosition.x + this.spaceHorizontal / 2 + this.cardSize.width / 2,
                y: row2y,
            };
        } else if (card.order == 10) {
            return {
                x: this.leftPeakPosition.x - this.spaceHorizontal - this.cardSize.width,
                y: row3y,
            };
        } else if (card.order == 11) {
            return {
                x: this.leftPeakPosition.x,
                y: row3y,
            };
        } else if (card.order == 12) {
            return {
                x: this.leftPeakPosition.x + this.spaceHorizontal + this.cardSize.width,
                y: row3y,
            };
        } else if (card.order == 13) {
            return {
                x: this.midPeakPosition.x - this.spaceHorizontal - this.cardSize.width,
                y: row3y,
            };
        } else if (card.order == 14) {
            return {
                x: this.midPeakPosition.x,
                y: row3y,
            };
        } else if (card.order == 15) {
            return {
                x: this.midPeakPosition.x + this.spaceHorizontal + this.cardSize.width,
                y: row3y,
            };
        } else if (card.order == 16) {
            return {
                x: this.rightPeakPosition.x - this.spaceHorizontal - this.cardSize.width,
                y: row3y,
            };
        } else if (card.order == 17) {
            return {
                x: this.rightPeakPosition.x,
                y: row3y,
            };
        } else if (card.order == 18) {
            return {
                x: this.rightPeakPosition.x + this.spaceHorizontal + this.cardSize.width,
                y: row3y,
            };
        } else if (card.order == 19) {
            return {
                x: this.leftPeakPosition.x - this.spaceHorizontal * 1.5 - this.cardSize.width * 1.5,
                y: row4y,
            };
        } else if (card.order == 20) {
            return {
                x: this.leftPeakPosition.x - this.spaceHorizontal * 0.5 - this.cardSize.width * 0.5,
                y: row4y,
            };
        } else if (card.order == 21) {
            return {
                x: this.leftPeakPosition.x + this.spaceHorizontal * 0.5 + this.cardSize.width * 0.5,
                y: row4y,
            };
        } else if (card.order == 22) {
            return {
                x: this.leftPeakPosition.x + this.spaceHorizontal * 1.5 + this.cardSize.width * 1.5,
                y: row4y,
            };
        } else if (card.order == 23) {
            return {
                x: this.midPeakPosition.x - this.spaceHorizontal * 0.5 - this.cardSize.width * 0.5,
                y: row4y,
            };
        } else if (card.order == 24) {
            return {
                x: this.midPeakPosition.x + this.spaceHorizontal * 0.5 + this.cardSize.width * 0.5,
                y: row4y,
            };
        } else if (card.order == 25) {
            return {
                x: this.midPeakPosition.x + this.spaceHorizontal * 1.5 + this.cardSize.width * 1.5,
                y: row4y,
            };
        } else if (card.order == 26) {
            return {
                x:
                    this.rightPeakPosition.x -
                    this.spaceHorizontal * 0.5 -
                    this.cardSize.width * 0.5,
                y: row4y,
            };
        } else if (card.order == 27) {
            return {
                x:
                    this.rightPeakPosition.x +
                    this.spaceHorizontal * 0.5 +
                    this.cardSize.width * 0.5,
                y: row4y,
            };
        } else if (card.order == 28) {
            return {
                x:
                    this.rightPeakPosition.x +
                    this.spaceHorizontal * 1.5 +
                    this.cardSize.width * 1.5,
                y: row4y,
            };
        }

        return {
            x: 0,
            y: 0,
        };
    }
}

export const display = new Display();
