import { Controller } from '@/core/controller';
import { bus } from '@/games/tripeaks/bus';
import { Card, CardOwner } from '@/core/models';
import { cardsQuery } from '@/state/cards/cards.query';
import { cardsService } from '@/state/cards/cards.service';
import { moveHistory } from '@/core/move-history';
import { gameService } from '@/state/game/game.service';
import { gameQuery } from '@/state/game/game.query';
import { GameStatus } from '@/core/models';
import { coreBus } from '@/core/core-bus';
import { triPeaksUtil } from '@/games/tripeaks/tripeaks-util';
import { cardSound } from '@/core/sound';

export class MoveController extends Controller {
    constructor() {
        super();
        this.subscribeTo(coreBus.cardClickEvent$, (ev) => {
            this.handleCardClick(ev.card);
            coreBus.gameMoveCompletedEvent$.next();
        });
        this.subscribeTo(bus.stockClickEvent$, () => {
            this.dealNextCard();
            coreBus.gameMoveCompletedEvent$.next();
        });
        this.subscribeTo(coreBus.undoMoveCmd$, () => {
            this.undoLastMove();
        });
    }

    private handleCardClick(card: Card) {
        if (card.owner == CardOwner.peaks) {
            if (!this.moveFromPeaksToDiscard(card)) {
                coreBus.shakeCardCmd$.next({
                    card,
                });
            }
        }
    }

    private moveFromPeaksToDiscard(card: Card) {
        if (!this.canMakeMove()) {
            return;
        }

        // check if card exposed
        if (!triPeaksUtil.isPeaksCardExposed(card)) {
            return false;
        }

        // get top discard
        const top = cardsQuery.getTopByOwner(CardOwner.discard);
        if (!top) {
            return false;
        }

        // check if valid move
        if (!this.canPutCardOnDiscard(card, top)) {
            return false;
        }

        moveHistory.startMove();

        // move card
        cardsService.update(card.id, {
            owner: CardOwner.discard,
            order: top.order + 1,
        });
        moveHistory.addState(card);

        // update expose cards
        const cardsFaceDown = cardsQuery.getAllByOwner(CardOwner.peaks).filter((c) => !c.isFaceUp);

        // if card exposed flip it
        const updates = cardsFaceDown.flatMap((c) => {
            if (triPeaksUtil.isPeaksCardExposed(c)) {
                moveHistory.addState(c);
                return [
                    {
                        ...c,
                        isFaceUp: true,
                        clickEnabled: true,
                    },
                ];
            } else {
                return [];
            }
        });
        cardsService.upsertMany(updates);

        coreBus.cardMoveCmd$.next({
            duration: 300,
            cardId: card.id,
        });

        gameService.increaseMoveCounter();
        moveHistory.endMove();
        cardSound.playScore();

        return true;
    }

    private canPutCardOnDiscard(card: Card, top: Card) {
        if (top.rank == 1 && card.rank == 13) {
            return true;
        }
        if (top.rank == 13 && card.rank == 1) {
            return true;
        }

        return top.rank + 1 == card.rank || top.rank - 1 == card.rank;
    }

    private dealNextCard() {
        if (!this.canMakeMove()) {
            return;
        }

        const topStock = cardsQuery.getTopByOwner(CardOwner.stock);
        if (!topStock) {
            return;
        }

        const topDiscard = cardsQuery.getTopByOwner(CardOwner.discard);
        const order = topDiscard ? topDiscard.order + 1 : 1;

        moveHistory.startMove();

        cardsService.update(topStock.id, {
            order,
            owner: CardOwner.discard,
            isFaceUp: true,
        });
        moveHistory.addState(topStock);

        coreBus.cardMoveCmd$.next({
            duration: 300,
            cardId: topStock.id,
        });

        gameService.increaseMoveCounter();
        moveHistory.endMove();
        cardSound.playMove();
    }

    private undoLastMove() {
        if (!this.canMakeMove()) {
            return true;
        }

        const move = moveHistory.pop();
        if (move) {
            cardsService.upsertMany(move.cards);
            move.cards.forEach((c) => {
                coreBus.cardMoveCmd$.next({
                    cardId: c.id,
                    duration: 300,
                });
            });
            gameService.increaseMoveCounter();
            gameService.increaseUndoCount();
        }
    }

    private canMakeMove() {
        const status = gameQuery.getValue().gameStatus;
        return status == GameStatus.running || status == GameStatus.dealCompleted;
    }
}
